/* @tailwind base;
@tailwind components;
@tailwind utilities; */

:root {
  --primary-color: #ef233c;
  --secondary-color: #f68b28;
}
body {
  overflow-x: hidden;
  position: relative;
}
* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

a {
  color: black;
  text-decoration: none;
}

.p-btn {
  color: white;
  border: none;
  background-color: var(--primary-color);
  padding: 0.4em 2em;
  border-radius: 5px;
  cursor: pointer;
}
.p-btn:hover {
  background-color: #c10017;
}

.primary-btn {
  color: white;
  border: none;
  background-color: var(--primary-color);
  padding: 1em 2em;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.primary-btn:hover {
  background-color: #c10017;
}

.secondary-btn {
  color: black;
  font-weight: 600;
  border: none;
  background-color: #dbdbdb;
  padding: 1em 2em;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-btn:hover {
  background-color: #c5c5c5;
}

.primary-input {
  padding: 1em;
  margin: 1em 0em;
}
.primary-textarea {
  padding: 1em;
  margin: 1em 0em;
}
.close-btn {
  width: 25px;
  cursor: pointer;
}
.close-btn img {
  width: 100%;
}
